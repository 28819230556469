// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-estructura-cat-js": () => import("./../../../src/pages/acerca/estructura/cat.js" /* webpackChunkName: "component---src-pages-acerca-estructura-cat-js" */),
  "component---src-pages-acerca-estructura-centroscore-js": () => import("./../../../src/pages/acerca/estructura/centroscore.js" /* webpackChunkName: "component---src-pages-acerca-estructura-centroscore-js" */),
  "component---src-pages-acerca-estructura-gepr-js": () => import("./../../../src/pages/acerca/estructura/gepr.js" /* webpackChunkName: "component---src-pages-acerca-estructura-gepr-js" */),
  "component---src-pages-acerca-estructura-interesados-js": () => import("./../../../src/pages/acerca/estructura/interesados.js" /* webpackChunkName: "component---src-pages-acerca-estructura-interesados-js" */),
  "component---src-pages-acerca-estructura-js": () => import("./../../../src/pages/acerca/estructura.js" /* webpackChunkName: "component---src-pages-acerca-estructura-js" */),
  "component---src-pages-acerca-financiamiento-js": () => import("./../../../src/pages/acerca/financiamiento.js" /* webpackChunkName: "component---src-pages-acerca-financiamiento-js" */),
  "component---src-pages-acerca-objetivos-js": () => import("./../../../src/pages/acerca/objetivos.js" /* webpackChunkName: "component---src-pages-acerca-objetivos-js" */),
  "component---src-pages-acerca-objetivos-objetivo-1-js": () => import("./../../../src/pages/acerca/objetivos/objetivo1.js" /* webpackChunkName: "component---src-pages-acerca-objetivos-objetivo-1-js" */),
  "component---src-pages-acerca-objetivos-objetivo-2-js": () => import("./../../../src/pages/acerca/objetivos/objetivo2.js" /* webpackChunkName: "component---src-pages-acerca-objetivos-objetivo-2-js" */),
  "component---src-pages-acerca-objetivos-objetivo-3-js": () => import("./../../../src/pages/acerca/objetivos/objetivo3.js" /* webpackChunkName: "component---src-pages-acerca-objetivos-objetivo-3-js" */),
  "component---src-pages-acerca-partners-js": () => import("./../../../src/pages/acerca/partners.js" /* webpackChunkName: "component---src-pages-acerca-partners-js" */),
  "component---src-pages-acerca-que-es-js": () => import("./../../../src/pages/acerca/que-es.js" /* webpackChunkName: "component---src-pages-acerca-que-es-js" */),
  "component---src-pages-actividades-convocatorias-js": () => import("./../../../src/pages/actividades/convocatorias.js" /* webpackChunkName: "component---src-pages-actividades-convocatorias-js" */),
  "component---src-pages-actividades-cursos-cortos-js": () => import("./../../../src/pages/actividades/cursos-cortos.js" /* webpackChunkName: "component---src-pages-actividades-cursos-cortos-js" */),
  "component---src-pages-actividades-otros-js": () => import("./../../../src/pages/actividades/otros.js" /* webpackChunkName: "component---src-pages-actividades-otros-js" */),
  "component---src-pages-actividades-publicaciones-js": () => import("./../../../src/pages/actividades/publicaciones.js" /* webpackChunkName: "component---src-pages-actividades-publicaciones-js" */),
  "component---src-pages-actividades-webinars-js": () => import("./../../../src/pages/actividades/webinars.js" /* webpackChunkName: "component---src-pages-actividades-webinars-js" */),
  "component---src-pages-calidadensalud-definiciones-js": () => import("./../../../src/pages/calidadensalud/definiciones.js" /* webpackChunkName: "component---src-pages-calidadensalud-definiciones-js" */),
  "component---src-pages-calidadensalud-importancia-js": () => import("./../../../src/pages/calidadensalud/importancia.js" /* webpackChunkName: "component---src-pages-calidadensalud-importancia-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-ecohorts-js": () => import("./../../../src/pages/proyectos/ecohorts.js" /* webpackChunkName: "component---src-pages-proyectos-ecohorts-js" */),
  "component---src-pages-proyectos-enmarcha-js": () => import("./../../../src/pages/proyectos/enmarcha.js" /* webpackChunkName: "component---src-pages-proyectos-enmarcha-js" */),
  "component---src-pages-proyectos-futuros-js": () => import("./../../../src/pages/proyectos/futuros.js" /* webpackChunkName: "component---src-pages-proyectos-futuros-js" */),
  "component---src-pages-proyectos-vozgente-js": () => import("./../../../src/pages/proyectos/vozgente.js" /* webpackChunkName: "component---src-pages-proyectos-vozgente-js" */),
  "component---src-pages-rd-ezequielgarciaelorrio-js": () => import("./../../../src/pages/rd/ezequielgarciaelorrio.js" /* webpackChunkName: "component---src-pages-rd-ezequielgarciaelorrio-js" */),
  "component---src-pages-rd-patriciajgarcia-js": () => import("./../../../src/pages/rd/patriciajgarcia.js" /* webpackChunkName: "component---src-pages-rd-patriciajgarcia-js" */)
}

